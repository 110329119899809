/* eslint no-undef: 0 */
import axios from 'axios'
import qs from 'qs'
import VueCookies from 'vue-cookies'
// 引入弹框
import { Message, MessageBox } from 'element-ui'

// 默认配置
axios.defaults.timeout = 10000
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.withCredentials = false

// 普通请求实例
const request = axios.create({
  baseURL: "/",
  maxRedirects: 0
})
let flag = false //判断登陆失效提示
// form-data类型请求实例
const requestFd = axios.create({
  baseURL: "/",
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [
    function (data) {
      Object.keys(data).forEach(key => {
        if (typeof data[key] === 'object') {
          data[key] = JSON.stringify(data[key])
        }
      })
      // 转为字符串
      return qs.stringify(data)
    }
  ]
})

/**
 * 拦截器成功回调
 * 所有接口返回0才正常返回resolve
 * 非0都reject出去
 * @param res
 * @returns {*}
 */
function resolve (res) {
  // console.log(res)
  if (res.headers.filename) {
    window.vm.$cookies.set('filename', res.headers.filename)
  }
  const data = res.data || {}

  if (res.status === 200) {
    if (typeof res.data != String) {
      // console.log(window.vm.$router)
      if (res.data.code && res.data.code != '1' && res.data.code != '-2') {
        Message({
          message: res.data.msg,
          type: 'error',
          duration: 5 * 1000
        })
        return
      } else if (res.data.code && res.data.code == '-2') {

        if (!flag) {
          flag = true
          window.vm.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
            center: true,
            showClose: false,
            callback: action => {
              //删除token
              window.vm.$cookies.remove("token");
              //跳回首页
              window.vm.$router.push("/homes/login");
              localStorage.clear();
              sessionStorage.clear();
            }
          });
        }

        // window.vm.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   // cancelButtonText: '取消',
        //   type: 'warning',
        //   center: true
        // }).then(() => {
        //   window.vm.$message({
        //     type: 'success',
        //     message: '删除成功!'
        //   });
        // })
        return
      }
      flag = false

      return Promise.resolve(res.data);
    } else {
      Promise.resolve(res);
    }

  } else {
    // console.log('失败了');
    return Promise.reject(res.data);
    // Message({
    //   message: '系统异常',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // return false
  }

}

// 失败(网络错误)
function reject (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400: error.message = '请求错误(400)'; break;
      case 401: error.message = '未授权,请登录(401)'; break;
      case 403: error.message = '拒绝访问(403)'; break;
      case 404: error.message = `请求地址出错: ${error.response.config.url}`; break;
      case 405: error.message = '请求方法未允许(405)'; break;
      case 408: error.message = '请求超时(408)'; break;
      case 500: error.message = '服务器内部错误(500)'; break;
      case 501: error.message = '服务未实现(501)'; break;
      case 502: error.message = '网络错误(502)'; break;
      case 503: error.message = '服务不可用(503)'; break;
      case 504: error.message = '网络超时(504)'; break;
      case 505: error.message = 'HTTP版本不受支持(505)'; break;
      default: error.message = `连接错误: ${error.message}`;
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
  } else {
    error.message = '连接服务器失败，请联系管理员'
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
  }

  return Promise.reject(error)
}
// 请求拦截
request.interceptors.request.use(function (config) {
  // 判断是否 需要加上token
  if (config.tokenStatus) {
    const token = window.vm.$cookies.get('token')
    config.headers.accessToken = `${token}`;
  }
  if (config.responseTypes) {
    // const token = window.vm.$cookies.get('token')
    config.responseType = `blob`;
  }
  return config
})




request.interceptors.response.use(resolve, reject)
requestFd.interceptors.response.use(resolve, reject)

export { request, requestFd }
